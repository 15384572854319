import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import nextSVG from '../assets/next.svg';
import likeSVG from '../assets/like.svg';
import '../styles/swiper.min.css';
import '../styles/customSwiper.css';

import TextOverlay from './textOverlay';

SwiperCore.use([Pagination]);

const reviews = [
  {
    id: 1,
    review: `Светлана прекрасный человек, агент, обладает знаниями, обонянием и упорством в решении всех вопрос на протяжении всей сделки. Я очень рада, что работала именно со Светланой! Имела опыт работы с другими агентами, так как была в жизни не первая сделка, в процессе работы, я ни разу не напряглась, все за меня делала Светлана, это просто сказка. А если учесть, что у нас был очень сложный, пожилой продавец, который очень скрупулёзно ко всему подходил и просто выносил мозг Светлане, то она вообще золото. Она уделала эту бабушку в два счета и все были довольны и бабушка и я.) Спасибо вам Светлана за работу, с вами приятно иметь дело!.`,
    author: 'Галина, 2022 год',
  },
  {
    id: 2,
    review: `Хотим от нашей небольшой семьи(Алла и Екатерина) выразить полный восторг от работы Светланы!Мы безгранично благодарны ей за продажу нашей 1-комнатной квартиры,искренние переживания и огромную поддержку!Весь процесс, пройденный вместе,доставил только добрые и положительные эмоции!Спасибо Вам огромное за Вас и Ваш труд!Успехов и наивысших достижений в Вашей нелегкой,но безумно интересной работе!Обязательно будем рекомендовать Вас всем нашим знакомым и друзьям!!!До новых встреч!`,
    author: 'Екатерина, 2022 год',
  },
  {
    id: 3,
    review: `От контакта со Светой остались только положительные воспоминания. Помогла выбрать квартиру и решиться на долевое строительство в ММ, просчитала, подсказала, поддержала, помогла. Я довольна, Света, спасибо!
    Это хороший специалист, выкладывается на все 100, и даже больше, не требуя взамен. Приятная в общении, красивая девушка, отличный собеседник.
    Рекомендую, останетесь довольны сотрудничеством.`,
    author: 'Елена, 2021 год',
  },
  {
    id: 4,
    review: `Хочу выразить Светлане благодарность от всей семьи! Очень рады, что обратились. Могу смело ее порекомендовать, специалист с большой буквы. Продавала нам трешку, быстро, четко, по очень хорошей цене. Умеет быстро находить общий язык и с продавцом и покупателем, советует по существу. Сэкономила нам кучу времени, сил и $!`,
    author: 'Юлия, 2021 год',
  },
]

const ReviewText = props => (
  <p {...props} />
)

const Author = props => (
  <div
    className="flex font-bold mt-50px justify-end"
  >
    <div {...props} />
  </div>
);

const Slider = () => {
  const swiperRef = useRef(null);
  const reviewsJsx = reviews.map(({ id, review, author }) => (
    <SwiperSlide className="relative rounded-20px bg-white swiper-shadow w-286px h-605px md:w-630px md:h-420px px-50px py-130px" key={id}>
      <div 
        className="absolute -top-35px"
        style={{
          left: "calc(50% - 45px)"
        }}
      >
        <div
          className="absolute -left-30px -top-35px rounded-20px w-150px h-150px"
          style={{
            background: '#FAF5FF',
            transform: 'rotate(12.47deg)',
          }} />
        <img
          alt=""
          src={likeSVG}
          className="relative w-80px h-80px z-10"
        />
      </div>
      <ReviewText className="text-16px font-light">{review}</ReviewText>
      <Author>{author}</Author>
    </SwiperSlide>
  ));
  return (
    <div className="relative">
      <div className="flex items-center my-8 px-0px sm:px-17px bg-white mx-0px lg:mx-130px">
        <button onClick={() => swiperRef.current.swiper.slidePrev()}>
          <img
            className="w-35px h-35px hidden lg:block absolute left-0 cursor-pointer"
            src={nextSVG}
            alt="Предыдущий слайд"
          />
        </button>
        <div
          className="-m-30px p-30px overflow-hidden pt-100px"
          style={{
            width: 'calc(100% + 60px)',
            height: 'calc(100% + 60px)',
          }}
        >
          <Swiper
            spaceBetween={50}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              1280: {
                slidesPerView: 2,
              }
            }}
            slidesPerView={2}
            ref={swiperRef}
            loop
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
              renderBullet: (index, className) => `<span class="${className} m-2 cursor-pointer w-10px h-10px border border-danger rounded-full"></span>`,
            }}
          >
            {reviewsJsx}
          </Swiper>
        </div>
        <button onClick={() => swiperRef.current.swiper.slideNext()}>
          <img
            className="w-35px h-35px hidden lg:block transform rotate-180 absolute right-0 cursor-pointer"
            src={nextSVG}
            alt="Следующий слайд"
          />
        </button>
      </div>
      <div className="swiper-pagination flex justify-center" />
    </div>
  )
}

const Reviews = () => (
  <div className="mt-60px">
    <TextOverlay name="reviews" text="Отзывы клиентов" />
    <div className="container">
      <Slider />
      <div className="flex flex-col items-center px-17px">
        <div className="font-light font-16px my-10">
          Буду рада, если ваш отзыв будет следующим. Все отзывы  вы можете почитать здесь
        </div>
        <a
          className="p-4 rounded-20px border border-danger text-danger"
          href="https://m.realt.by/realtors/person/325034/"
        >Отзывы на realt.by</a>
      </div>
    </div>
  </div>
);

export default Reviews;
